@import url('https://fonts.cdnfonts.com/css/cinzel');

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */ // dành cho các trình duyệt web
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Remove default margin */ // chuyển về mặc định 0 
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

.font-w100 {
  font-weight: 100 !important;
}
.font-w200 {
  font-weight: 200 !important;
}
.font-w300 {
  font-weight: 300 !important;
}
.font-w400 {
  font-weight: 400 !important;
}
.font-w500 {
  font-weight: 500 !important;
}
.font-w600 {
  font-weight: 600 !important;
}
.font-w700 {
  font-weight: 700 !important;
}
.font-w800 {
  font-weight: 800 !important;
}
.font-w900 {
  font-weight: 900 !important;
}

body {
  
  background:
  url('../assets/wolf/background.png') ; 
  background-color: #01081e;
  width: 100%;
  height: auto;
  background-size: cover ;
  background-repeat: no-repeat; /* Ngăn chặn việc lặp lại hình nền */
  background-position: top center; /* Đặt hình nền vào giữa trang và trung tâm của body */
  // filter:blur(10px);
  // -webkit-filter: blur(10px);

  p{
    @include mw-768 {
      font-size: 20px;
      line-height: 150%;
    }
  }
  
}

h1, h2 {
  color: rgba(255, 227, 172, 1);
  text-align: center;
  font-family: Creepster;
  font-size: 73px;
  font-style: normal;
  font-weight: 700;
  line-height: 92px; /* 153.333% */
  @include mw-768 {
    font-size: 30px;
    line-height: 150%;
  }
}


.tittle-group {
  color: #FFE3AC;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 28px;
  @include mw-768 {
    font-size: 14px;
    line-height: 150%;
    margin-top: 20px;
  }
}