/* media queries */
/* device */

$mw-768: 768px; 
$mw-1024: 1024px; // màn 1024
$mw-1440: 1440px; // màn 1440
$mw-1920: 1920px;
$mw-2560: 2560px;


@mixin mw-768 {
  @media (max-width: #{$mw-768}) {
    @content;
  }
}

@mixin mw-1024 {
  @media (max-width: #{$mw-1024}) {
    @content;
  }
}

@mixin mw-1440 {
  @media (max-width: #{$mw-1440}) {
    @content;
  }
}
@mixin mw-1920 {
  @media (max-width: #{$mw-1920}) {
    @content;
  }
}

@mixin mw-2560 {
  @media (max-width: #{$mw-2560}) {
    @content;
  }
}

