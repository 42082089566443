.wolf {
 
  b {
    font-family: Cinzel;
    font-size: 25px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 227, 172, 1);
   justify-content:center !important ;
  }

  p {
    font-family: Cinzel;
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(242, 242, 242, 1);
  }

  .text-section1 {
    position: absolute;
    top: 241.2rem;
    width: 291px;
    height: auto;
    gap: 28px;
    left: 5%;


    @include mw-1024 {
      margin-top: -1.5rem;
      left: 1.2rem;
    }
    @include mw-768 {
      text-align: center;
      width: 90%;
      margin-top: 144.5rem;
      padding: 0 15px;
      left: 5%;
      b {
        margin: auto;
      }
    }
  }

  .text-section2 {
    position: absolute;
    top: 241.2rem;
    width: 291px;
    height: auto;
    gap: 28px;
    right: 5%;

    @include mw-1024 {
      margin-top: -1.5rem;
      right: 0.5rem;
    }

    @include mw-768 {
      text-align: center;
      margin-top: 176.2rem;
      width: 90%;
      padding: 0 15px;
      left: 5%;
      b {
        margin: auto;
      }
    }
  }
  .text-url {
    position: absolute;
    top: 280rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    h2 {
      font-size: 25px !important;
      color:  #ffe3ac;
      -webkit-text-stroke: 0.25px black;
    }
    @media(min-width:306px) and (max-width:707px) {
      margin-top: 180rem;
    }
  }
  
}

.wolf-content {
  display: flex;
  align-items: center;
  flex-direction: column; // sắp xếp phần tử từ trên xuống dưới

  .wolf-img {
    display: flex;
    position: relative;
    justify-content: center !important;
    width: 100% !important;
    height: auto;
    animation-name: wolf1;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    img {
      position: absolute;
      top: -62rem;
      width: 920px ;
      height: 840px ;
    }

    @keyframes wolf1 {
      0% {
        top: 0;
        left: 0;
      }

      50% {
        top: -20px;
        left: 0;
      }

      100% {
        top: 0;
        left: 0;
      }
    }
   
    @include mw-1440 {
      img {
        width: 860px;
      }
    }
    @include mw-1024 {
      margin-top: -20px;
      width: 250px;
      height: auto;
    }
    @include mw-768 {
      img {
        margin-top: 30px;
        width: 100%;
        height: auto;
      }
    }
  }
  .button-solar {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
    animation: r1 3s ease-in-out infinite;
    /*linear*/
    border: 10px #056bfa21 solid;
    border-radius: 15px;
    --border-right: 0px;
    --text-stroke-color: rgba(255, 227, 172, 1);
    --animation-color: white;
    --fs-size: 2rem;
    text-decoration: none;
    font-size: var(--fs-size);
    position: relative;
    text-transform: uppercase;
    // color: transparent; // màu trong suốt
    -webkit-text-stroke: 1px var(--text-stroke-color);

    span {
      font-family: Creepster;
      white-space: nowrap;
      line-height: 92px;
      font-size: 30px;
      font-weight: 400;
      line-height: 35px;
    }

    .hover-text {
      position: absolute;
      box-sizing: border-box;
      content: attr(data-text);
      color: var(--animation-color);
      width: 0%;
      inset: 0;
      margin-top: 9.6px;
      color: transparent;
      border-right: var(--border-right) solid var(--animation-color);
      overflow: hidden;
      transition: 1.5s;
      -webkit-text-stroke: 0.5px var(--animation-color);
      animation: r2 2s ease-in-out infinite;
    }
  }

  .button-solar:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 50px var(--animation-color));
  }

  @keyframes r1 {
    50% {
      transform: rotate(-1deg) rotateZ(-10deg);
    }
  }

  @keyframes r2 {
    50% {
      transform: rotateX(-65deg);
    }
  }
}

.wolf-center {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 12.5rem;
  width: 100%;
  height: auto;
  box-shadow: 0px 20px 19.5px 0px rgba(0, 0, 0, 0.25);

  .group-copy {
    display: inline-flex;
    align-items: flex;
    gap: 3rem;

    p {
      font-family: Inter !important;
    }
  }

  .wolf-buy {
    position: absolute;
    justify-content: center;
    top: 24.5rem;
    z-index: 2;

    img {
      width: 447px;
      height: 215px;

      @include mw-768 {
        margin-top: 20rem;
        // width: 100%;
        height: max-content;
      }
    }
  }
}

.btn-social {
  position: absolute;
  top: 27.5rem;
  z-index: 3;
  border-radius: 10px;
  width: 20.325rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 {
    color: #ffe3ac;
  }
  @include mw-768 {
    margin-top: 19.8rem;
  }
  .svg-copy {
    width: 3.5rem;
    height: 3.5rem;
    color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    svg {
      width: 30px;
      height: 100%;
    }

    @include mw-768 {
      width: 31px;
    }
  }
  p {
    color: var(--Color-text, #ffe3ac);
    font-family: Arial, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.36px;
    @include mw-768 {
      font-size: 13px;
    }
  }
}

.btn-pinksale {
  .pinksale-left {
    position: absolute;
    top: 4.1rem;
    left: 1px;
    z-index: 3;

    @include mw-768 {
      top: 3.2rem;
      left: 1rem;
    }
  }

  .pinksale-right {
    position: absolute;
    top: 3.8rem;
    z-index: 3;
    right: 2px;
    
    @include mw-768 {
      top: 3.2rem;
      right: 1rem;
    }
  }
}

.social-svg {
  margin-top: 38.5rem;
  justify-content: center;
  align-items: center;
  height: auto;
  display: flex;
  gap: 1.2rem;
  z-index: 3;
  @include mw-768 {
    margin-top: 59rem;
  }

  .btn-svg {
    width: 3.5rem;
    height: 3.5rem;
    color: transparent;
    border: none;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
  }
}

.slide-show-collection {
  position: relative;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @include mw-1024 {
    width: 70%;
  }
  @include mw-768 {
    margin-top: 5rem;
  }

  .swiper-slide {
    width: 210px;
    height: 250px;
    filter: drop-shadow(0px 30px 70px rgba(10, 10, 10, 0.5));
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: self-start;
  }

  .swiper-slide-active {
    filter: blur(0px);
  }

  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active {
    background: #fff;
  }

  .swiper-3d .swiper-slide-shadow-left,
  .swiper-3d .swiper-slide-shadow-right {
    background-image: none;
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 92px;
  }
}

.our-clients {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 10rem;
  position: relative;
  height: auto;
  h2 {
    color: #fff;
  }

  @include mw-768 {
    padding-top: 0px;
   
  }

  h2 {
    padding-top: 24px;
  }
}

.img-client2 {
  position: absolute;
  top: 10%;
  overflow: hidden;
  width: 100%;
  height: auto;
  z-index: 3;
}

.clients-group {
  padding-top: 83px;
  margin-top: 15.8px;
  padding-left: 93px;
  padding-right: 93px;
  width: 80%;

  @include mw-1024 {
    padding-left: 0;
    padding-right: 0;
    // padding-top: 30px;
  }

  @include mw-768 {
    padding-top: 30px;
    margin-top: 55px;
  }

  .clients-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .item-svg {
      @include mw-768 {
        margin-top: 30px;
        width: 36px;
      }
    }
    svg {
      height: 60px;
      width: 500px;
    }
    @include mw-1024 {
      gap: 10px;
    }

    @include mw-768 {
      flex-wrap: wrap;
    }
  }
}

.btn-view-story {
  margin-top: 33px;
  color: rgba(255, 227, 172, 1);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 190%;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  margin-bottom: 200px;
}

.collection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 240rem;
  position: relative;
  
  @include mw-768 {
    margin-top: 390rem;
  }
  #tittle-1 {
    @include mw-1024 {
      font-size: 14px;
    }
    @include mw-768 {
      margin-top: 10px;
      padding: 0 68px !important;
      font-size: 10.5px !important;
    }
  }
  #tittle-2 {
    margin-top: -50px;
    @include mw-1024 {
      font-size: 16px;
    }
    @include mw-768 {
      padding: 0 30px !important;
    }
  }

  

  .btn-explore {
    svg {
      width: 7.397px;
      height: 7.397px;
      margin-right: 9px;
      margin-left: 0;
    }
  }

  h2 {
    margin-top: 10px;
  }

  .tittle-footer {
    margin-top: -40px;

    @include mw-768 {
      margin-top: 0px;
    }
  }
}

.tittle-group {
  margin-top: 45px;
  padding-top: 5px;
  border-radius: 15px;

  @include mw-768 {
    margin-top: 150px;
    font-size: 15px !important;
    width: 100%;
    padding: 0 38px !important;
  }
}

.slide-collection {
  display: flex;
  justify-content: center;

  img {
    @include mw-1024 {
      width: 90%;
    }

    width: 80%;
  }
}

.disclaimer {
  padding-top: 82px;
  margin-top: 10rem;

}

.disclaimer-cont {
  border-radius: 25px;
  background: url("../assets/disclaimer/background.png") center center/100% 100%
    no-repeat;
  border-radius: 25px;
  backdrop-filter: blur(20px);
  width: 100%;
  background-size: cover;
  display: flex;
  height: 447px;

  @include mw-768 {
    height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
}

.disclaimer-center {
  margin-left: 20px;
  padding-right: 75px;
  flex-grow: 1;
  display: flex;
  justify-content: center;

  .tittle-footer {
    color: var(--Color-text, #ffe3ac);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 160% */
    letter-spacing: -0.14px;

    @include mw-768 {
      width: 100%;
      font-size: 15px;
    }
  }

  @include mw-768 {
    margin-left: 20px;
    margin-right: 20px;
    // margin-top: 20px;
    padding-right: 0;
  }
}

.disclaimer-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  

  h2 {
    color: var(--Color-text, #ffe3ac);
    text-align: center;
    font-family: Inter;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 92px;
  }

  @include mw-768 {
    h2 {
      font-size: 30px !important;
    }
  }
}

.disclaimer-left {
  @include mw-1024 {
    display: flex;
    align-items: center;
  }

  img {
    width: 512px;
    height: 551px;
    margin-top: -50px;
    @include mw-1440 {
      width: 320px;
      height: 420px;
      margin-top: 10px;
    }
    @include mw-1024 {
      margin-top: 0px;
      margin-left: -10px;
      width: 250px;
      height: max-content;
    }
  }
}

.disclaimer-right {
  img {
    width: 337px;
    height: 354px;
    margin-top: 30px;
    @include mw-1440 {
      width: 230px;
      margin-top: 50px;
      margin-left: -35px;
    }
    @include mw-1024 {
      width: 210px;
      height: max-content;
      margin-left: -40%;
      margin-top: 100px;
    }
    @include mw-768 {
      margin: auto;
    }
  }
}

.retangle-title1 {
  position: relative;
  margin: auto !important;
  display: inline-block;
  align-items: center;
  justify-content: center;
  text-align: justify;
  white-space: nowrap;

  .img {
    margin: auto !important;
    display: block;
    max-width: 100%;
    display: block;
  }

  @include mw-768 {
    img {
      width: 100%;
    }

    p {
      font-size: 20px !important;
    }
  }

  .lantern1 {
    position: absolute;
    left: -15%;
    top: 25%;
  }

  .lantern1-1 {
    position: absolute;
    left: -4%;
    top: 32%;
    width: 52px;
    height: 73px;
  }

  .lantern2 {
    position: absolute;
    right: -14%;
    top: 35%;
  }

  .lantern2-2 {
    position: absolute;
    right: -5%;
    top: 35%;
    width: 52px;
    height: 73px;
  }

  .text-on-image {
    position: absolute;
    top: 30%;
    /* Đưa vị trí lên giữa theo chiều dọc */
    left: 50%;
    /* Đưa vị trí lên giữa theo chiều ngang */
    transform: translate(-50%, -50%);
    /* Dịch chuyển vị trí để nằm chính giữa */
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    width: 354px;
    height: 83px;
    border-radius: 19px;
    background-color: rgba(237, 221, 193, 0.1);
    @include mw-768 {
      width: 154px;
      height: 33px;
    }
  }

  p {
    color: var(--Color-text, #ffe3ac);
    text-shadow: 0px 6px 6.8px rgba(0, 0, 0, 0.5);
    font-family: "Germania One";
    overflow: hidden;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: auto;
    text-align: center;
  }
}

.slide-show {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  @include mw-1024 {
    overflow: hidden;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-slide {
  color: #fff;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.14px;
}

.subtitle-slide {
  color: #fff;
  //
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 160% */
  letter-spacing: -0.14px;
}

.btn-explore-more {
  color: rgb(8, 13, 58);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 190%;
  /* 22.8px */
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.btn-prev-next-group {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: -2rem;
  .custom-prev-button {
    margin-left: -72px;
    @include mw-768 {
      margin-left: -35px;
    }
  }

  .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 52%;
    top: 88%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));

    &:after {
      content: "";
    }
    @include mw-768 {
      width: 2.3rem;
      top: 83%;
    }
  }
  .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }
}

.img-layer1 {
  margin-top: 3rem;
  z-index: 3;
}
#tittle-0 {
  @include mw-1024 {
    font-size: 14px !important;
    padding-top: 50px;
  }
  @include mw-768 {
    font-size: 10.5px !important;
    padding: 0 50px;
    margin-top: 38px;
  }
}
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.bonkenomics {
  position: absolute;
  top: 120rem;
  // left: -1rem;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.85;
  color: white;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  border-radius: 20px;
  padding: 2em;
  width: 100%;
  padding: 5% 0 5% 0;
  h2 {
    color: #eeeeee;
    letter-spacing: 2px;
    stroke: #FFFFCC;
    -webkit-text-stroke: 0.25px black;
  }
  span {
    font-family: Creepster;
  }
  b {
    font-size: 15px;
    color: #00fe7f;
  }

  .score {
    border-radius: 10px;
    padding: 30px;
    @include mw-768 {
      width: 100%;
    }
    .certik {
      position: relative;
      letter-spacing: 4px;
      font-size: 30px;
      background-color: #151618;
      padding: 30px 30px 0 30px;
      overflow: hidden;
      border-radius: 10px;
      font-weight: 800;
      .bar {
        position: absolute;
        top: 100%; /* Đặt bar ở giữa theo chiều dọc */
        left: 50%; /* Đặt bar ở giữa theo chiều ngang */
        transform: translate(-50%, -50%) rotate(205deg); /* Dịch chuyển bar ra ngoài giữa */
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 20px solid #3f4142;
        border-bottom-color: #00fe7f;
        border-right-color: #00fe7f;
      }
      .score-value {
        color: lime;
        font-weight: bold;
        font-size: 20px;
        margin-top: 60px;
        margin-left: 10px;
      }
      
    }
  }

  .container-stats {
    width: 90%;
    display: flex;
    margin-left: 4%;
    padding: 20px 30px 0px 30px;
    gap: 20px;
    @include mw-768 {
      display: block;
    }
    .col-4 {
      box-sizing: border-box;
      background-color: #151618;
      border-radius: 20px;
      padding: 40px;
      font-size: 28px;
      @include mw-768 {
        width: 100%;
        margin: 15px auto;
      }
    }
  }
}

.where-solar {
  position: absolute;
  top: 180rem;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.88;
  width: 100%;
  border-radius: 10px;
  padding: 5% 0 5% 0;
  h2 {
    color: #eeeeee;
    letter-spacing: 2px;
    stroke: #FFFFCC;
    -webkit-text-stroke: 0.25px black;
  }
  .container-solars {
    display: flex;
    text-transform: uppercase;
    font-family: Creepster;
    text-transform: lowercase;
    margin-left: 3.6%;
    padding: 20px 30px 10px 30px;
    gap: 20px;
    width: 90%;
    @include mw-768 {
      display: block;
    }
    .col-3 {
      box-sizing: border-box;
      width: 25%;
      text-align: center;
      background-color: #151618;
      padding: 20px;
      border-radius: 20px;
      color: #fff;
      font-size: 26px;

      @include mw-768 {
        width: 100% !important;
        margin: 10px auto;
      }
    }
  }
}

.sec1 {
  display: block;
  margin: auto;
  
}
@media (max-width:412px){
  .btn-pinksale {
    .pinksale-left {
        top: 3.2rem !important;
        left: 1.2rem !important;
    }
    .pinksale-right {
        top: 2.8rem !important;
        right: 1.9rem !important;
    }
  }
  
  
  .text-section1 {
    margin-top: 151.2rem !important;
  }
}
@media (max-width:431px){
  .btn-pinksale {
    .pinksale-left {
        top: 4rem !important;
        left: 0.3rem !important;
    }
    .pinksale-right {
        top: 3.7rem !important;
        right: 0.8rem !important;
    }
  }
  .text-section1 {
    margin-top: 152rem !important;
  }
  
  
}

@media (max-width:768px){
  .btn-pinksale {
    .pinksale-left {
        top: 4.7rem;
        left: -0.8rem;
    }
    .pinksale-right {
        top: 4.7rem;
        right: -0.8rem;
    }
  }
}
@media (min-width:769px) and (max-width:1125px) {
  .text-section1 {
    top: 360rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .text-section2 {
    top: 390rem !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .text-url {
    top: 420rem !important;
  }
  .collection {
    margin-top: 380rem;
  }
  .disclaimer-right {
    img {
     margin-right:-40px !important; 
    }
  }
  .disclaimer-left {
    img {
      margin-left: -5px;
    }
  }
  .disclaimer-content {
    h2 {
      font-size: 50px;
    }
    margin-left: -30px;
  }
}

@media (min-width: 1610px) and (max-width:1689px) {
  .wolf{
    .text-section1{
      top: 255rem !important;
    }
    .text-section2{
      top: 255rem !important;
    }
    
  }
}
@media(min-width: 1690px) and (max-width:1790px) {
  .text-section1 {
    top: 270rem !important;
  }
  .text-section2 {
    top: 270rem !important;
  }
  .text-url {
    top: 295rem !important;
  }
}
@media (min-width:1791px) and (max-width:1999px) {
  .collection {
    margin-top: 290rem !important;
  }
  .text-url {
    top: 335rem !important;
  }
  .text-section1 {
    top: 295rem !important;
  }
  .text-section2 {
    top: 295rem !important;
  }
}
@media (min-width: 2000px) and (max-width: 2510px) {
  
  .wolf{
    p{
      font-size: 16px;
    }
    .text-section1{
      top: 310rem;
    }
    .text-section2{
      top: 310rem;
    }
    
  }
  .our-clients{
    margin-top: 30rem;
  }
  .collection{
    margin-top: 305rem;
  }
  .disclaimer{
    padding-top: 25rem;
  }
  
}
@media ( min-width: 2050px) and ( max-width: 2300px) {
  .text-section1 {
    top:360rem !important;
  }
  .text-section2 {
    top:360rem !important;
  }
  .text-url {
    top: 410rem !important;
  }
  .collection {
    margin-top: 380rem;
  }
}
@media ( min-width: 2301px) and ( max-width: 2510px) {
  .text-section1 {
    top:380rem !important;
  }
  .text-section2 {
    top:380rem !important;
  }
  .text-url {
    top: 435rem !important;
  }
  .collection {
    margin-top: 385rem;
  }
}
@media (min-width: 2511px) and (max-width: 3250px) {
  
  .wolf-img {
    margin-top: -42rem;
    img {
      width: 1480px !important;
      height: 1350px !important;
      
    }
  }
  .wolf {
    b {
      font-size: 45px;
      
    }
    p {
      font-size: 25px;
      line-height: 1.5 !important;
    }
  }
  .wolf-buy {
    margin-top: 25rem;
    img {
      width: 720px !important;
      height: 400px !important;
    }
  }
  .btn-social {
    margin-top: 28.3rem;
    width: 520px !important;
  }
  .social-svg {
    margin-top: 76.1rem;
  }
  .btn-pinksale {
    .pinksale-left {
      top:7.7rem;
      left: 2rem;
      font-size: 35px;
    }
    .pinksale-right {
      top:6.6rem;
      width: 85px;
    }
  }
  .text-section1 {
    margin-top: 199rem !important;
    width: 800px !important;
  }
  .text-section2 {
    margin-top: 199rem !important;
    width: 800px !important;
  }
 
  .collection {
    margin-top: 488rem !important ;
  }
  .our-clients {
    margin-top: 50rem !important;
  }
  .disclaimer {
    margin-top: 40rem;
  }
}
@media(min-width: 3000px) {
  .text-section1 {
    top: 270rem !important;
  }
  .text-section2 {
    top: 270rem !important;
  }
  .text-url {
    top: 531rem !important;
  }
}

